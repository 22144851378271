@import "../node_modules/@syncfusion/ej2-react-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-treegrid/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-notifications/styles/material.css";

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 100;
  src: url("styles/fonts/NotoSans-Light.ttf") format("woff");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: normal;
  src: url("styles/fonts/NotoSans-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: 500;
  src: url("styles/fonts/NotoSans-Medium.ttf") format("woff");
}

@font-face {
  font-family: "Noto Sans CJK";
  font-style: normal;
  font-weight: bold;
  src: url("styles/fonts/NotoSans-Bold.ttf") format("woff");
}

/* Noto Sans Font */

*:not(.e-btn-icon, .e-icons, .editor-html-content *) {
  font-family: "Noto Sans KR", "Noto Sans", sans-serif !important;
  font-size: 1rem;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
}

html {
  font-family: Helvetica, -apple-system, "Segoe UI", "Helvetica Neue",
    sans-serif;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  /* 스크롤바 숨김 - IE */
  -ms-overflow-style: none;
}

/* 스크롤바 숨김 chromium, firefox 기반*/
::-webkit-scrollbar {
  display: none;
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropzone {
  margin-top: 1%;
  min-height: 100px !important;
  height: 130px !important;
}
.dropzone-text {
  font-size: 1.2rem !important;
}

.e-grid {
  cursor: default;
}

.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(33, 33, 33, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 5px black;
  font-size: large;
}

.e-grid .e-headercell {
  height: 35px;
}

.e-grid .e-rowcell {
  font-size: 14px;
}

.e-ddl.e-input-group.e-control-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
}

.MuiChip-root .MuiChip-label {
  font-size: 14px;
}

textarea {
  border: 1px solid #e0e0e0;
  outline-color: #e0e0e0;
}

.react-pdf__Document {
  display: flex !important;
}

.swal2-container {
  z-index: 100000;
}
.e-daterangepicker.e-popup {
  left: 250px !important;
}

.top-level-item {
  background-color: #f0faf7;
}

.e-ddl.e-input-group .e-input-group-icon,
.e-ddl.e-input-group.e-control-wrapper .e-input-group-icon:hover,
.e-ddl.e-input-group .e-input-group-icon::before,
.e-ddl.e-input-group .e-input-group-icon::after,
.e-ddt.e-input-group.e-control-wrapper .e-ddt-icon::before,
.e-ddt.e-input-group.e-control-wrapper .e-ddt-icon::after {
  font-size: 11px;
}

.e-control {
  font-size: 14px !important;
}

text#linear_linearLabel {
  font-size: 14px !important;
}
