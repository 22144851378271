::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;

    border-radius: 6px;
    background-color: rgba(255,255,255,0.4);
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.3);
    border-radius: 6px;
}